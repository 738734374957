<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				subscribe:{},
				belli_collection:{},
				cape_collection:{},
				msg_belli:{},
				msg_cape:{},
				msg_subscribe:{},
			}
		},
		computed:{
			upic(){
				return this.user ? this.user.pic : false
			},
			ufullname(){
				return this.user ? this.user.fullname : "-"
			},
			uemail(){
				return this.user ? this.user.email : "-"
			},
			uphone(){
				return this.user ? this.user.phone : "-"
			},
			uaddress(){
				return this.user ? this.user.address : "-"
			},
		},
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 100)
			$(document).ready(() => {
				AOS.init()
			})

			this.getSubscribe()
		},
		methods: {
			getSubscribe() {
				this.$root.topProgress.done()
				Gen.apirest("/data-dashboard",{},(err,resp)=>{
					if(err) console.log(err)
					this.subscribe = resp.data.featured_subscribe
					this.belli_collection = resp.data.featured_belli_collection
					this.cape_collection = resp.data.featured_cape_collection
					this.msg_belli = resp.data.msg_belli
					this.msg_cape = resp.data.msg_cape
					this.msg_subscribe = resp.data.msg_subscribe
				})
			},
			openPurchase(v){
				VModal.openModal("ModalDetailPurchase",v,()=>{})
			},
			openAccountSetting(v){
				VModal.openModal("ModalAccountSetting",v,()=>{})
			},
			countCollection(id) {
				Gen.apirest("/hit-count-collection", {id:id}, (err,resp)=>{
					if(err) console.log(err)
					console.log("success")
				})
			},
			countSubscribe(id) {
				Gen.apirest("/hit-count-subscribe", {id:id}, (err,resp)=>{
					if(err) console.log(err)
					console.log("success")
				})
			}
		},
		watch: {}
	};
</script>
<template>
	<div id="content">
			<section class="section nobottommargin notopmargin wall-bg">
				<div class="container">
					<ol class="breadcrumb">
						<li class="breadcrumb-item active">{{web.lbl_dashboard}}</li>
					</ol>
					<div class="row">
						<div class="col-lg-4">
							<div class="box-el acc-info">
								<div class="row align-items-center">
									<div class="col-md-6 col-lg-12">
										<div class="profile">
											<!-- <img :src="uploader(upic)" alt="Profile Photo"> -->
											<h4>{{ufullname}}</h4>
											<span>{{uemail}}</span>
											<a href="javascript:;" @click="openAccountSetting"
												class="button button-red mt-2">{{web.edit_acc}}</a>
										</div>
									</div>
									<div class="col-md-6 col-lg-12">
										<div class="personal-info">
											<h4>{{web.title_p_info}}</h4>
											<div class="form-group">
												<label for="">{{web.lbl_phone}}</label>
												<span>+62 {{uphone}}</span>
											</div>
											<div class="form-group">
												<label for="">{{web.lbl_full_address}}</label>
												<span>{{uaddress}}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-8">
							<div class="dash-content">
								<div class="heading-title private">
									<h2>{{web.lbl_featured_product}}</h2>
								</div>
								<div class="row justify-content-center">
									<div class="col-md-6 col-lg-12">
										<div class="blog-item" v-if="cape_collection">
											<div class="row">
												<div class="col-lg-4">
													<div class="bi-thumbnail" style="height:100%">
														<a href="javascript:;">
															<VImg class="img_full" :lazySrc="uploader(cape_collection.image)"
																:alt="cape_collection.title"></VImg>
														</a>

													</div>
												</div>
												<div class="col-lg-8">
													<div class="bi-desc">
														<h4>{{cape_collection.title}}</h4>
														<h5>{{web.lbl_tasting_note}}</h5>
														<p v-html="cape_collection.description"></p>
														<a @click="countCollection(cape_collection.id)"
															:href="'https://wa.me/62'+msg_cape.phone+'?text='+msg_cape.message" target="_blank"
															class="button button-red">{{web.lbl_purchase}}</a>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-6 col-lg-12">
										<div class="blog-item" v-if="belli_collection">
											<div class="row">
												<div class="col-lg-4">
													<div class="bi-thumbnail">
														<a href="javascript:;">
															<VImg class="img_full" :lazySrc="uploader(belli_collection.image)"
																:alt="belli_collection.title"></VImg>
														</a>
													</div>
												</div>
												<div class="col-lg-8">
													<div class="bi-desc">
														<h4>{{belli_collection.title}}</h4>
														<h5>{{web.lbl_tasting_note}}</h5>
														<p v-html="belli_collection.description"></p>
														<a @click="countCollection(belli_collection.id)"
															:href="'https://wa.me/62'+msg_belli.phone+'?text='+msg_belli.message"
															target="_blank" class="button button-red">{{web.lbl_purchase}}</a>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-6 col-lg-12">
										<div class="blog-item" v-if="subscribe">
											<div class="row">
												<div class="col-lg-4">
													<div class="bi-thumbnail">
														<a href="javascript:;">
															<VImg class="img_full" :lazySrc="uploader(subscribe.image)" :alt="subscribe.title">
															</VImg>
														</a>
													</div>
												</div>
												<div class="col-lg-8">
													<div class="bi-desc">
														<h4>{{subscribe.title}}</h4>
														<!-- <h5>{{web.lbl_tasting_note}}</h5> -->
														<p v-html="subscribe.description"></p>
														<a @click="countSubscribe(subscribe.id)"
															:href="'https://wa.me/62'+msg_subscribe.phone+'?text='+msg_subscribe.message+''"
															target="_blank" class="button button-red">{{web.btn_subscribe_now}}</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
</template>